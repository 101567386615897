import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./Header.css";

const Header = ({ handleLogout }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [email] = useState(sessionStorage.getItem("email"));
  const [rol] = useState(sessionStorage.getItem("rol"));
  const navigate = useNavigate();

  const openAsociarModal = () => {
    setModalOpen(true);
  };

  const closeAsociarModal = () => {
    setModalOpen(false);
  };

  const handleLogoutAndRedirect = () => {
    handleLogout();
    navigate("/landing");
  };

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-dark bg-darkblue">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {rol === "Administrador" && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/control-produccion">
                      Control de Producción Diario
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      Ruta de Transportes
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/totales-mes">
                      Totales por meses
                    </Link>
                  </li>
                  {/* Dropdown para agrupar las opciones */}
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Administración
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <Link className="dropdown-item" to="/clientes">
                          Clientes
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/usuarios">
                          Usuarios
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/empleados">
                          Empleados
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/transportistas">
                          Transportistas
                        </Link>
                      </li>
                      {/*<li><Link className="dropdown-item" to="/horarios-clientes">Horarios Clientes</Link></li>*/}
                      {/*<li><Link className="dropdown-item" to="/lineas-repartos">Líneas de Repartos</Link></li>*/}
                      {/*<li><Link className="dropdown-item" to="/repartos">Repartos</Link></li>*/}
                      {/*<li><Link className="dropdown-item" to="/transportes">Transportes</Link></li>*/}
                      <li>
                        <Link className="dropdown-item" to="/vehiculos">
                          Vehículos
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/tipos-vehiculos">
                          Tipos de Vehículos
                        </Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}

              {rol === "Encargado" && (
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/control-produccion">
                      Control de Producción Diario
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/dashboard">
                      Ruta de Transportes
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="profile-section d-flex align-items-center">
            <img
              src={"/images/perfil.png"}
              alt="Profile"
              className="profile-image"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button
                  className="dropdown-item"
                  onClick={handleLogoutAndRedirect}
                >
                  Cerrar Sesión
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {modalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeAsociarModal}>
              &times;
            </span>
          </div>
        </div>
      )}

      <style jsx>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          background: white;
          padding: 20px;
          border-radius: 8px;
          width: 300px;
          text-align: center;
          position: relative;
        }
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
          font-size: 20px;
        }
        .dropdown-menu {
          min-width: 200px;
        }
        .profile-image {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      `}</style>
    </header>
  );
};

export default Header;

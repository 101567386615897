// src/MainRouter.js
/*import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const Landing = lazy(() => import('./components/Landing'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const RecuperarPassword = lazy(() => import('./components/RecuperarPassword'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const Clientes = lazy(() => import('./components/entidades/Clientes'));
const Empleados = lazy(() => import('./components/entidades/Empleados'));
const HorariosClientes = lazy(() => import('./components/entidades/HorariosClientes'));
const LineasRepartos = lazy(() => import('./components/entidades/LineasRepartos'));
const Repartos = lazy(() => import('./components/entidades/Repartos'));
const TiposVehiculos = lazy(() => import('./components/entidades/TiposVehiculos'));
const Transportes = lazy(() => import('./components/entidades/Transportes'));
const Transportistas = lazy(() => import('./components/entidades/Transportistas'));
const Usuarios = lazy(() => import('./components/entidades/Usuarios'));
const Vehiculos = lazy(() => import('./components/entidades/Vehiculos'));

const MainRouter = ({ token, rol, handleLogin }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/empleados" element={<Empleados />} />
        <Route path="/horarios-clientes" element={<HorariosClientes />} />
        <Route path="/lineas-repartos" element={<LineasRepartos />} />
        <Route path="/repartos" element={<Repartos />} />
        <Route path="/tipos-vehiculos" element={<TiposVehiculos />} />
        <Route path="/transportes" element={<Transportes />} />
        <Route path="/transportistas" element={<Transportistas />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/vehiculos" element={<Vehiculos />} />
        <Route path="/dashboard" element={<Dashboard rol={rol} />} />

        <Route
          path="/login"
          element={token ? <Navigate to="/dashboard" /> : <Login handleLogin={handleLogin} />}
        />
        <Route path="/register" element={<Register />} />
        <Route
          path="/recovery-pass"
          element={token ? <RecuperarPassword /> : <Navigate to="/login" />}
        />
        <Route path="/" element={<Navigate to="/landing" />} />
      </Routes>
    </Suspense>
  );
};

export default MainRouter;
*/

// src/MainRouter.js
import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import TotalesMes from './components/TotalesMes';

const Landing = lazy(() => import('./components/Landing'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const RecuperarPassword = lazy(() => import('./components/RecuperarPassword'));
const Dashboard = lazy(() => import('./components/Dashboard'));
const RutaTransportes = lazy(() => import('./components/RutaTransportes'));
const ControlProduccion = lazy(() => import('./components/ControlProduccion'));
const Clientes = lazy(() => import('./components/entidades/Clientes'));
const Empleados = lazy(() => import('./components/entidades/Empleados'));
const HorariosClientes = lazy(() => import('./components/entidades/HorariosClientes'));
const LineasRepartos = lazy(() => import('./components/entidades/LineasRepartos'));
const Repartos = lazy(() => import('./components/entidades/Repartos'));
const TiposVehiculos = lazy(() => import('./components/entidades/TiposVehiculos'));
const Transportes = lazy(() => import('./components/entidades/Transportes'));
const Transportistas = lazy(() => import('./components/entidades/Transportistas'));
const Usuarios = lazy(() => import('./components/entidades/Usuarios'));
const Vehiculos = lazy(() => import('./components/entidades/Vehiculos'));
const EmployeeDashboard = lazy(() => import('./components/EmployeeDashboard'));

const MainRouter = ({ token, rol, handleLogin, produccionData, fechaHoy }) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Routes>
        <Route path="/landing" element={<Landing />} />
        {/*<Route path="/login" element={token ? <Navigate to="/control-produccion" /> : <Login handleLogin={handleLogin} />} />*/}
        <Route
          path="/login"
          element={
            token ? (
              rol === "Administrador" || rol === "Encargado" ? (
                <Navigate to="/control-produccion" />
              ) : (
                <Navigate to="/employee-dashboard" />
              )
            ) : (
              <Login handleLogin={handleLogin} />
            )
          }
        />
        <Route path="/register" element={<Register />} />
        <Route path="/recovery-pass" element={token ? <RecuperarPassword /> : <Navigate to="/login" />} />
        <Route path="/dashboard" element={<Dashboard rol={rol} />} />
        <Route path="/ruta-transportes" element={<RutaTransportes />} />
        <Route
          path="/control-produccion"
          element={<ControlProduccion fechaHoy={fechaHoy} />}
        />
        <Route
          path="/employee-dashboard"
          element={<EmployeeDashboard data={produccionData} fecha={fechaHoy} />}
        />
        <Route path="/" element={<Navigate to="/landing" />} />
        <Route path='/clientes' element={<Clientes />} />
        <Route path='/empleados' element={<Empleados />} />
        <Route path='/horarios-clientes' element={<HorariosClientes />} />
        <Route path='/lineas-repartos' element={<LineasRepartos />} />
        <Route path='/repartos' element={<Repartos />} />
        <Route path='/tipos-vehiculos' element={<TiposVehiculos />} />
        <Route path='/transportes' element={<Transportes />} />
        <Route path='/transportistas' element={<Transportistas />} />
        <Route path='/usuarios' element={<Usuarios />} />
        <Route path='/vehiculos' element={<Vehiculos />} />
        <Route path='/totales-mes' element={<TotalesMes />} />
      </Routes>
    </Suspense>
  );
};

export default MainRouter;

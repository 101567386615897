/*import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material";

import axiosInstance from "../axiosConfig";
import moment from "moment";

const TotalesMes = () => {
  const [totales, setTotales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTotalesMes();
  }, []);

  const fetchTotalesMes = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("cuadros_produccion");
      const cuadros = response.data;

      // Agrupar por mes
      const totalesPorMes = cuadros.reduce((acc, cuadro) => {
        const mes = moment(cuadro.fecha).format("MM-YYYY");
        if (!acc[mes]) {
          acc[mes] = {
            nombre: moment(cuadro.fecha).format("MMMM YYYY"),
            kg_ant: 0,
            kg_ent: 0,
            kg_rech: 0,
            total_kg: 0,
            pend_alb: 0,
          };
        }

        acc[mes].kg_ant += parseFloat(cuadro.kg_dia_anterior) || 0;
        acc[mes].kg_ent += parseFloat(cuadro.kg_entrada) || 0;
        acc[mes].kg_rech += parseFloat(cuadro.kg_rechazados) || 0;
        acc[mes].total_kg += parseFloat(cuadro.total_kg) || 0;
        acc[mes].pend_alb += parseFloat(cuadro.pendiente_albaran) || 0;

        return acc;
      }, {});

      // Convertir a array
      const totalesArray = Object.entries(totalesPorMes).map(([mes, data]) => ({
        mes,
        ...data,
      }));

      setTotales(totalesArray);
    } catch (err) {
      console.error("Error al obtener los cuadros de producción:", err);
      setError("No se pudieron cargar los totales del mes.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Totales del Mes
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mes</TableCell>
              <TableCell align="center">Kg Ant.</TableCell>
              <TableCell align="center">Kg Ent.</TableCell>
              <TableCell align="center">Kg Rech.</TableCell>
              <TableCell align="center">Total Kg</TableCell>
              <TableCell align="center">Pend. Alb.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totales.map((mesData) => (
              <TableRow key={mesData.mes}>
                <TableCell>{mesData.nombre}</TableCell>
                <TableCell align="center">
                  {mesData.kg_ant.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {mesData.kg_ent.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {mesData.kg_rech.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {mesData.total_kg.toFixed(2)}
                </TableCell>
                <TableCell align="center">
                  {mesData.pend_alb.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Container>
  );
};

export default TotalesMes;
*/

/*import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../axiosConfig";
import moment from "moment";

// Registrar componentes de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TotalesMes = () => {
  const [totales, setTotales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTotalesMes();
  }, []);

  const fetchTotalesMes = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("cuadros_produccion");
      const cuadros = response.data;

      // Agrupar por mes
      const totalesPorMes = cuadros.reduce((acc, cuadro) => {
        const mes = moment(cuadro.fecha).format("MM-YYYY");
        if (!acc[mes]) {
          acc[mes] = {
            nombre: moment(cuadro.fecha).format("MMMM YYYY"),
            kg_ant: 0,
            kg_ent: 0,
            kg_rech: 0,
            total_kg: 0,
            pend_alb: 0,
          };
        }

        acc[mes].kg_ant += parseFloat(cuadro.kg_dia_anterior) || 0;
        acc[mes].kg_ent += parseFloat(cuadro.kg_entrada) || 0;
        acc[mes].kg_rech += parseFloat(cuadro.kg_rechazados) || 0;
        acc[mes].total_kg += parseFloat(cuadro.total_kg) || 0;
        acc[mes].pend_alb += parseFloat(cuadro.pendiente_albaran) || 0;

        return acc;
      }, {});

      // Convertir a array
      const totalesArray = Object.entries(totalesPorMes).map(([mes, data]) => ({
        mes,
        ...data,
      }));

      setTotales(totalesArray);
    } catch (err) {
      console.error("Error al obtener los cuadros de producción:", err);
      setError("No se pudieron cargar los totales del mes.");
    } finally {
      setLoading(false);
    }
  };

  const renderCharts = (data) => {
    const chartData = {
      labels: ["Kg Ant.", "Kg Ent.", "Kg Rech.", "Total Kg", "Pend. Alb."],
      datasets: [
        {
          label: "Totales",
          data: [
            data.kg_ant,
            data.kg_ent,
            data.kg_rech,
            data.total_kg,
            data.pend_alb,
          ],
          backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#EF5350", "#AB47BC"],
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: `Totales de ${data.nombre}`,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Totales del Mes
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <Grid container spacing={4}>
          {totales.map((mesData) => (
            <Grid item xs={12} md={6} lg={4} key={mesData.mes}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {mesData.nombre}
                  </Typography>
                  <Typography>Kg Ant.: {mesData.kg_ant.toFixed(2)}</Typography>
                  <Typography>Kg Ent.: {mesData.kg_ent.toFixed(2)}</Typography>
                  <Typography>Kg Rech.: {mesData.kg_rech.toFixed(2)}</Typography>
                  <Typography>Total Kg: {mesData.total_kg.toFixed(2)}</Typography>
                  <Typography>Pend. Alb.: {mesData.pend_alb.toFixed(2)}</Typography>
                  <div style={{ marginTop: "20px" }}>{renderCharts(mesData)}</div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default TotalesMes;
*/

import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axiosInstance from "../axiosConfig";
import moment from "moment";
import "moment/locale/es";

// Registrar componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Configurar Moment para español
moment.locale("es");

const TotalesMes = () => {
  const [totales, setTotales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTotalesMes();
  }, []);

  const fetchTotalesMes = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("cuadros_produccion");
      const cuadros = response.data;

      // Agrupar por mes
      const totalesPorMes = cuadros.reduce((acc, cuadro) => {
        const mes = moment(cuadro.fecha).format("YYYY-MM");
        if (!acc[mes]) {
          acc[mes] = {
            nombre: moment(cuadro.fecha).format("MMMM YYYY"),
            fechaOrden: moment(cuadro.fecha).toDate(), // Para ordenar cronológicamente
            kg_ant: 0,
            kg_ent: 0,
            kg_rech: 0,
            total_kg: 0,
            pend_alb: 0,
          };
        }

        acc[mes].kg_ant += parseFloat(cuadro.kg_dia_anterior) || 0;
        acc[mes].kg_ent += parseFloat(cuadro.kg_entrada) || 0;
        acc[mes].kg_rech += parseFloat(cuadro.kg_rechazados) || 0;
        acc[mes].total_kg += parseFloat(cuadro.total_kg) || 0;
        acc[mes].pend_alb += parseFloat(cuadro.pendiente_albaran) || 0;

        return acc;
      }, {});

      // Convertir a array y ordenar por fecha
      const totalesArray = Object.entries(totalesPorMes)
        .map(([mes, data]) => ({
          mes,
          ...data,
        }))
        .sort((a, b) => a.fechaOrden - b.fechaOrden);

      setTotales(totalesArray);
    } catch (err) {
      console.error("Error al obtener los cuadros de producción:", err);
      setError("No se pudieron cargar los totales del mes.");
    } finally {
      setLoading(false);
    }
  };

  const renderCharts = (data) => {
    const chartData = {
      labels: ["Kg Día Anterior", "Kg Entregados", "Kg Rechazados", "Total Kg", "Pendiente Albarán"],
      datasets: [
        {
          label: "Totales",
          data: [
            data.kg_ant,
            data.kg_ent,
            data.kg_rech,
            data.total_kg,
            data.pend_alb,
          ],
          backgroundColor: [
            "#42A5F5",
            "#66BB6A",
            "#FFA726",
            "#EF5350",
            "#AB47BC",
          ],
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: `Totales de ${data.nombre}`,
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Totales por meses
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {!loading && !error && (
        <Grid container spacing={4}>
          {totales.map((mesData) => (
            <Grid item xs={12} md={6} lg={4} key={mesData.mes}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {mesData.nombre.charAt(0).toUpperCase() +
                      mesData.nombre.slice(1)}
                  </Typography>
                  {/*<Typography><strong>Kg Día Anterior:</strong> {mesData.kg_ant.toFixed(2)} kg</Typography>*/}
                  <Typography><strong>Kg Entrada:</strong> {mesData.kg_ent.toFixed(2)} kg</Typography>
                  <Typography>
                    <strong>Kg Rechazados:</strong> {mesData.kg_rech.toFixed(2)} kg
                  </Typography>
                  <Typography>
                    <strong>Total Kg Cerrados:</strong> {mesData.total_kg.toFixed(2)} kg
                  </Typography>
                  <Typography>
                    <strong>Pendiente Albarán:</strong> {mesData.pend_alb.toFixed(2)} kg
                  </Typography>
                  <div style={{ marginTop: "20px" }}>
                    {renderCharts(mesData)}
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default TotalesMes;

import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://app-xvok.onrender.com/api/',  // URL del Backend
  headers: {
    'Content-Type': 'application/json'
  }
});

export default axiosInstance;

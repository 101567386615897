/*import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Alert from 'react-bootstrap/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from './components/Login';
import Register from './components/Register';
import RecuperarPassword from './components/RecuperarPassword';
import Header from './components/Header';
import Landing from './components/Landing';
import Dashboard from './components/Dashboard';
import Clientes from './components/entidades/Clientes';
import Empleados from './components/entidades/Empleados';          // Importa el componente CRUD de Empleados
import HorariosClientes from './components/entidades/HorariosClientes';  // Importa el componente CRUD de HorariosClientes
import LineasRepartos from './components/entidades/LineasRepartos';    // Importa el componente CRUD de LineasRepartos
import Repartos from './components/entidades/Repartos';               // Importa el componente CRUD de Repartos
import TiposVehiculos from './components/entidades/TiposVehiculos';    // Importa el componente CRUD de TiposVehiculos
import Transportes from './components/entidades/Transportes';          // Importa el componente CRUD de Transportes
import Transportistas from './components/entidades/Transportistas';    // Importa el componente CRUD de Transportistas
import Usuarios from './components/entidades/Usuarios';                // Importa el componente CRUD de Usuarios
import Vehiculos from './components/entidades/Vehiculos';              // Importa el componente CRUD de Vehiculos

const queryClient = new QueryClient();

const App = () => {
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [nombre, setNombre] = useState(sessionStorage.getItem('nombre'));
  const [rol, setRol] = useState(sessionStorage.getItem('rol'));
  const [selectedImage, setSelectedImage] = useState(null);
  const [alertaVisible, setAlertaVisible] = useState(false);
  const [alertaTipo, setAlertaTipo] = useState('');
  const [alertaMensaje, setAlertaMensaje] = useState('');

  const handleLogin = (token, nombre) => {
    setToken(token);
    setNombre(nombre);

    const rol = nombre === 'admin' ? 'Administrador' : 'Empleado';
    setRol(rol);

    sessionStorage.setItem('token', token);
    sessionStorage.setItem('nombre', nombre);
    sessionStorage.setItem('rol', rol);
  };

  const handleLogout = () => {
    setToken(null);
    setNombre(null);
    setRol(null);
    sessionStorage.clear();
  };

  const showAlert = (tipo, mensaje) => {
    setAlertaTipo(tipo);
    setAlertaMensaje(mensaje);
    setAlertaVisible(true);
  };

  const handleCloseAlert = () => {
    setAlertaVisible(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div>
          {token && <Header handleLogout={handleLogout} />}
          <div className='main-content'>
            <Routes>
              <Route path='/landing' element={<Landing />} />
              <Route path='/clientes' element={<Clientes />} />
              <Route path='/empleados' element={<Empleados />} />                  
              <Route path='/horarios-clientes' element={<HorariosClientes />} />   
              <Route path='/lineas-repartos' element={<LineasRepartos />} />       
              <Route path='/repartos' element={<Repartos />} />                    
              <Route path='/tipos-vehiculos' element={<TiposVehiculos />} />       
              <Route path='/transportes' element={<Transportes />} />              
              <Route path='/transportistas' element={<Transportistas />} />        
              <Route path='/usuarios' element={<Usuarios />} />                    
              <Route path='/vehiculos' element={<Vehiculos />} />                 
              <Route path='/dashboard' element={<Dashboard rol={rol} />} />

              <Route
                path="/login"
                element={token ? <Dashboard rol={rol} /> : <Login handleLogin={handleLogin} />}
              />
              <Route path="/register" element={<Register />} />
              <Route path="/recovery-pass" element={token ? <RecuperarPassword /> : <Navigate to="/login" />} />
              <Route path="/" element={<Navigate to="/landing" />} />
            </Routes>
          </div>
        </div>

        {alertaVisible && (
          <Alert variant={alertaTipo || 'danger'} onClose={handleCloseAlert} dismissible>
            <Alert.Heading>{alertaTipo === 'success' ? '¡Éxito!' : 'Error'}</Alert.Heading>
            <p>{alertaMensaje || 'No hay mensaje disponible'}</p>
          </Alert>
        )}

      </Router>
    </QueryClientProvider>
  );
};

export default App;
*/

// src/App.js
/*import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, Snackbar, Alert, AlertTitle } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Header from './components/Header';
import MainRouter from './MainRouter';

const queryClient = new QueryClient();

const App = () => {
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [nombre, setNombre] = useState(sessionStorage.getItem('nombre'));
  const [rol, setRol] = useState(sessionStorage.getItem('rol'));

  const [alertaVisible, setAlertaVisible] = useState(false);
  const [alertaTipo, setAlertaTipo] = useState('');
  const [alertaMensaje, setAlertaMensaje] = useState('');

  const handleLogin = useCallback((token, nombre) => {
    const rol = nombre === 'admin' ? 'Administrador' : 'Empleado';
    setToken(token);
    setNombre(nombre);
    setRol(rol);
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('nombre', nombre);
    sessionStorage.setItem('rol', rol);
  }, []);

  const handleLogout = useCallback(() => {
    setToken(null);
    setNombre(null);
    setRol(null);
    sessionStorage.clear();
  }, []);

  const showAlert = useCallback((tipo, mensaje) => {
    setAlertaTipo(tipo);
    setAlertaMensaje(mensaje);
    setAlertaVisible(true);
  }, []);

  const handleCloseAlert = () => {
    setAlertaVisible(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          {token && <Header handleLogout={handleLogout} />}
          <div className="main-content">
            <MainRouter token={token} rol={rol} handleLogin={handleLogin} />
          </div>
          <Snackbar
            open={alertaVisible}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity={alertaTipo || 'error'}>
              <AlertTitle>{alertaTipo === 'success' ? '¡Éxito!' : 'Error'}</AlertTitle>
              {alertaMensaje || 'No hay mensaje disponible'}
            </Alert>
          </Snackbar>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
*/

import React, { useState, useCallback, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { CssBaseline, Snackbar, Alert, AlertTitle } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { format } from "date-fns";
import theme from "./theme";
import Header from "./components/Header";
import MainRouter from "./MainRouter";

const queryClient = new QueryClient();

const App = () => {
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [nombre, setNombre] = useState(sessionStorage.getItem("nombre"));
  const [rol, setRol] = useState(sessionStorage.getItem("rol"));

  const [alertaVisible, setAlertaVisible] = useState(false);
  const [alertaTipo, setAlertaTipo] = useState("");
  const [alertaMensaje, setAlertaMensaje] = useState("");

  // Datos de producción simulados
  const [produccionData, setProduccionData] = useState({
    "2024-11-04": [
      { id: "1", descripcion: "Producción de camisas", cantidad: 100 },
      { id: "2", descripcion: "Producción de pantalones", cantidad: 200 },
    ],
    // Otros días con sus respectivos datos de producción...
  });

  const fechaHoy = format(new Date(), "yyyy-MM-dd");

  const handleLogin = useCallback((token, nombre) => {
    //const rol = nombre === 'admin' ? 'Administrador' : 'Empleado';
    const rol =
      nombre === "admin"
        ? "Administrador"
        : nombre === "encargado"
        ? "Encargado"
        : "Empleado";

    setToken(token);
    setNombre(nombre);
    setRol(rol);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("nombre", nombre);
    sessionStorage.setItem("rol", rol);
  }, []);

  const handleLogout = useCallback(() => {
    setToken(null);
    setNombre(null);
    setRol(null);
    sessionStorage.clear();
  }, []);

  const showAlert = useCallback((tipo, mensaje) => {
    setAlertaTipo(tipo);
    setAlertaMensaje(mensaje);
    setAlertaVisible(true);
  }, []);

  const handleCloseAlert = () => {
    setAlertaVisible(false);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          {token && <Header handleLogout={handleLogout} />}
          <div className="main-content">
            <MainRouter
              token={token}
              rol={rol}
              handleLogin={handleLogin}
              produccionData={produccionData}
              fechaHoy={fechaHoy}
            />
          </div>
          <Snackbar
            open={alertaVisible}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert onClose={handleCloseAlert} severity={alertaTipo || "error"}>
              <AlertTitle>
                {alertaTipo === "success" ? "¡Éxito!" : "Error"}
              </AlertTitle>
              {alertaMensaje || "No hay mensaje disponible"}
            </Alert>
          </Snackbar>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
